<template>
  <div>
    <el-card>
      <div slot="header" class="clearfix">
        <span>团队通知</span>
      </div>
      <form-create v-model="where" :label-width="'auto'" inline :components="[
        {name:'form-item',props:{prop:'type',label:'通知类型', type:'select', multiple:true,remote:true,filterable:true,data:{
                      url:'/xapi/user.group/options'
                      ,params:{team_id,type:'notice_type'}
                  }}},
        {name:'form-item',props:{prop:'keyword',label:'通知标题'}},
       {name:'form-item',props:{prop:'issue_user_id',label:'发布人' , type:'select', multiple:true,remote:true,filterable:true,data:{
                      url:'/xapi/user.group/memberList'
                      ,params:{team_id}
                      ,labelOptions:{label:'realname',value:'user_id'}
                  }
                }
              },
    ]">
        <el-form-item class="operate" slot="after">
          <el-button type="primary" size="mini"  @click="$refs.table.reset(where)">查询</el-button>
          <el-button type="primary" size="mini"  @click="$refs.table.reset(where = {})">重置</el-button>
          <el-button type="primary" size="mini" @click="$refs.createDialog.visibled(currentData = {})" v-if="teamUser.is_master">创建通知</el-button>
        </el-form-item>
      </form-create>
      <com-table
          ref="table"
          :data=" {
        url:'/xapi/user.group/noticeList',
        params:{team_id}
      }"
          :columns="[
        {prop:'id',label:'编号',width:'80' , align:'center'},
        {prop:'title',label:'通知标题' ,minWidth:'150' , align:'center'},
        {prop:'content',label:'通知内容',minWidth:'220' , align:'center',showOverflowTooltip:true,template({row}){return $jquery('<span>'+row.content+'</span>').text();}},
        {prop:'create_time',label:'时间',width:'160' , align:'center'},
        {prop:'apply_user_name',label:'申请人',width:'120' , align:'center'},
        {prop:'create_user_name',label:'创建人',width:'120' , align:'center'},
        {prop:'statusStr',label:'状态',width:'120' , align:'center',},
        {fixed:'right',label:'操作',width:'180' , slot:'action' , align:'center'},
      ]"
          :where="where"
      >
        <el-button-group slot="action" slot-scope="{row}">
          <el-button type="text" size="small" :disabled="row.status !== 0"
                     @click="noticeRead(row.id,({msg})=>{ $handleMessage(msg || '操作成功！' , 'success' , ()=>{ $refs.table.reload(where);}) })"
                     v-if="!(row.type === 6&&row.user_id===userInfo.id)"
          >标记已读</el-button>
          <template v-if="row.type === 1011 && teamUser.is_master">
            <el-button type="text" size="small" :disabled="row.apply_status!==0" @click="$refs.jujueDialog.visibled((currentData = {...row,agree:false}) && true)">拒绝</el-button>
            <el-button type="text" size="small" :disabled="row.apply_status!==0" @click="handleCommand('confirm','你确定通过此入团审核吗？','/api/v1_0_0.user_group/approval_join_apply',{status:1,log_id:row.from_user_id},()=>{$refs.table.reset(where);})">同意</el-button>
          </template>
          <template v-else>
            <el-button type="text" size="small" @click="$refs.catDialog.visibled(currentData = {...row})" v-if="!(row.type === 6&&row.user_id===userInfo.id)">查看</el-button>
            <el-button type="text" size="small" :disabled="!(row.type === 6&&row.user_id===userInfo.id)" @click="$refs.createDialog.visibled(currentData = {...row})">编辑</el-button>
          </template>
          <el-button type="text"
                     size="small"
                     v-if="row.type === 6&&row.user_id===userInfo.id"
                     @click="handleCommand('confirm','你确定删除此通知吗？','/xapi/user.group/noticeDel',{id:row.id},()=>{$refs.table.reset(where);})"
          >删除</el-button>
        </el-button-group>
      </com-table>
    </el-card>
    <diy-dialog @confirm="()=>{currentData.status === 0 && noticeRead(currentData.id,()=>{ $refs.table.reload(where); })}"
                @cancel="()=>{currentData.status === 0 && noticeRead(currentData.id,()=>{ $refs.table.reload(where); })}"
                ref="catDialog"
                width="860px"
                :title="'查看通知'"
                center>
      <form-create ref="catForm" v-model="currentData" :components="[
            {name:'form-item',props:{prop:'create_user_name',label:'创建人',isShowText:true}},
            {name:'form-item',props:{prop:'title',label:'通知标题',isShowText:true}},
            {name:'form-item',props:{prop:'content',label:'通知内容',type:'editor',isShowText:true}},
            {name:'form-item',props:{prop:'create_time',label:'创建时间',isShowText:true}},
          ]" :label-width="'100px'">
      </form-create>
    </diy-dialog>
    <diy-dialog ref="createDialog"
                width="860px"
                :title="'添加通知'"
                center>
      <form-create ref="createForm" v-model="currentData" :components="[
        // {name:'form-item',props:{prop:'user_id',label:'创建人',defaultValue:userInfo.id,required:true ,disabled:true, type:'select',data:{ url:'/xapi/user/getList', labelOptions:{value:'id',label:'nickname' || 'realname'} }}},
        {name:'form-item',props:{prop:'user_id',label:'创建人',defaultValue:userInfo.id,required:true ,disabled:true, type:'select',data:{ url:'/xapi/user.user/teachList', labelOptions:{value:'id',label:'nickname' || 'realname'} }}},
        // {name:'form-item',props:{prop:'user_id',label:'创建人',defaultValue:userInfo.id,required:true ,disabled:true, type:'select',data:{ url:'/xapi/user.user/getList', labelOptions:{value:'id',label:'nickname' || 'realname'} }}},
        {name:'form-item',props:{prop:'title',label:'通知标题',required:true }},
        {name:'form-item',props:{prop:'content',label:'通知内容',required:true,type:'editor',editorConfig:{maximumWords:800,toolbars:[
            [
            'source', //源代码
            'undo', //撤销
            'redo', //重做
            'bold', //加粗
            'italic', //斜体
            'underline', //下划线
            'strikethrough', //删除线
            'subscript', //下标
            'superscript', //上标
            'emotion', //表情
            'spechars', //特殊字符
            'justifyleft', //居左对齐
            'justifyright', //居右对齐
            'justifycenter', //居中对齐
            'insertimage',
          ]
        ]}}},
        // {name:'form-item',props:{prop:'sort',label:'排序权重',type:'number' }},
          ]" :label-width="'100px'">
      </form-create>
      <div slot="footer">
        <el-button style="margin-right: 50px;" type="primary" @click="$refs.createDialog.visibled(false)">取 消</el-button>
        <el-button type="primary" @click="$refs.createForm.validate((data,valid)=>{
        if(valid){
          $http.post('/xapi/user.group/noticeSave' , {...$route.params,...(data || {})}).then(({msg})=>{
               $handleMessage(msg || '操作成功！' , 'success' , ()=>{ $refs.table.reset(where);$refs.createDialog.visibled(false);})
              }).catch(()=>{})
        }
      })">确 定</el-button>
      </div>
    </diy-dialog>
    <diy-dialog ref="jujueDialog" :title="'拒绝'" center >
      <form-create v-model="currentData" :components="[
          {name:'form-item',props:{prop:'reason',label:'理由',type:'textarea',placeholder:'请输入驳回理由'}},
        ]" :label-width="'100px'">
      </form-create>
      <div slot="footer">
        <el-button style="margin-right: 50px;" type="primary" @click="$refs.jujueDialog.visibled(false)">取 消</el-button>
        <el-button type="primary" @click="handleCommand('request','/api/v1_0_0.user_group/approval_join_apply',{status:2,log_id:currentData.from_user_id,reply:currentData.reason},()=>{$refs.table.reset(where);$refs.jujueDialog.visibled(false)})">确 定</el-button>
      </div>
    </diy-dialog>
  </div>
</template>

<script>
export default {
  props: {
    team_id:{
      default: () => 0,
    },
    teamUser:{
      type:Object,
      default: () => { return {}; },
    },
  },
  data(){
    return {
      where:{},
      currentData:{}
    }
  },
  methods:{
    noticeRead(id,cb){
      this.$http.post('/xapi/user.group/noticeRead' , {team_id:this.team_id,id:id}).then((res)=>{
        cb && cb(res);
      }).catch(()=>{})
    }
  }
}
</script>

<style scoped lang="less">
</style>